import React, { useContext } from 'react';
import { ResourceBrowserContext } from './ResourceBrowser';
import pluralize from 'pluralize';

const ResourceBrowserHeader = ({ count }) => {
  const { filters, setFilters } = useContext(ResourceBrowserContext);

  const typeFilterPresent = filters.contentType.length !== 0;

  return (
    <div className="resource-browser__header">
      <div className="resource-browser__header-inner">
        <div className="resource-browser__active-type">
          {typeFilterPresent ? pluralize(filters.contentType[0].title) : 'All'}
        </div>
        <div className="resource-browser__items-count">{`${count} results`}</div>
      </div>
    </div>
  );
};

export default ResourceBrowserHeader;
