import React, { useContext } from 'react';
import { ResourceBrowserContext } from './ResourceBrowser';

const FilterPill = ({ children, ...rest }) => {
  return (
    <div className="filter-pill" {...rest}>
      <div className="filter-pill__title">{children}</div>
      <div className="filter-pill__icon" />
    </div>
  );
};
const ResourceBrowserPillsList = () => {
  const { filters, setFilters } = useContext(ResourceBrowserContext);

  // map through the filters object by key
  // and append the group key to each filter title/slug pair
  const activeFilters = Object.keys(filters)
    .map((key) => {
      return filters[key].map((filter) => ({ ...filter, groupSlug: key }));
    })
    .flat();

  const handlePillClick = ({ title, slug, groupSlug }) => {
    setFilters({
      ...filters,
      [groupSlug]: filters[groupSlug].filter((f) => f.slug !== slug),
    });
  };

  return (
    <div className="resource-browser__filter-list">
      <div className="resource-browser__filter-list-inner">
        {activeFilters.length !== 0 && (
          <div className="pills-list">
            {activeFilters.map((filter, index) => {
              return (
                <div className="pills-list__item" key={index}>
                  <FilterPill onClick={() => handlePillClick(filter)}>
                    {filter.title}
                  </FilterPill>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default ResourceBrowserPillsList;
