import React, { useState } from 'react';
import ResourceCard from '../ResourceCard';
import { Button, ButtonContainer } from '../Button';

const ResourceBrowserCardsList = ({ cards, limit }) => {
  const [showAllItems, setShowAllItems] = useState(false);

  return (
    <div className="resource-browser__cards-list">
      <div className="resource-browser__cards-list-inner">
        <div className="resource-browser__cards">
          {cards
            .slice(0, showAllItems ? cards.length : limit)
            .map((resource, index) => {
              return (
                <div key={index} className="resource-browser__card-item">
                  <ResourceCard {...resource} key={index} />
                </div>
              );
            })}
        </div>
        {!showAllItems && cards.length > limit && (
          <ButtonContainer
            align="center"
            className="resource-browser__load-mode"
          >
            <Button type="button" onClick={() => setShowAllItems(true)}>
              Load more
            </Button>
          </ButtonContainer>
        )}
      </div>
    </div>
  );
};

ResourceBrowserCardsList.defaultProps = {
  cards: [],
  limit: 6,
};
export default ResourceBrowserCardsList;
