import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import { ResourceBrowser } from '../components/ResourceBrowser';
import MediaHero from '../components/MediaHero';
import SiteMeta from '../components/SiteMeta';

const ResourcesPage = ({ data }) => {
  const {
    allContentfulResource,
    contentfulResourceLibrary: {
      resourceLibraryHero,
      metaTitle,
      metaDescription: { metaDescription },
    },
  } = data;


  const resources = allContentfulResource.edges.map((n) => n.node);
  return (
    <Layout>
      <SiteMeta title={metaTitle} description={metaDescription} />
      <MediaHero {...resourceLibraryHero} />
      <ResourceBrowser resources={resources} />
    </Layout>
  );
};

export default ResourcesPage;

export const query = graphql`
  query {
    contentfulResourceLibrary(slug: {eq: "pe-resources"}) {
      metaTitle
      metaDescription {
        metaDescription
      }
      resourceLibraryHero {
        ...Navigator
      }
    }
    allContentfulResource(filter: {microsite: {ne: "wfe"}, slug: {ne: "dummy-resource"}}) {
      edges {
        node {
          ...ResourceCard
        }
      }
    }
  }
`;
