import React, { useContext, useMemo } from 'react';
import { ResourceBrowserContext, filterByType } from './ResourceBrowser';
import { Button } from '../Button';
import Dropdown from '../Dropdown';

const ResourceBrowserControls = () => {
  const {
    resources,
    resetFilters,
    filters,
    setFilters,
    filterByType,
    hasActiveFilters,
  } = useContext(ResourceBrowserContext);

  const getFilterOptionsByGroupSlug = (items, slug) => {
    return items.map((resource) => resource[slug]).flat();
  };

  const handleSelectOption = (value, groupSlug) => {
    // We expect the dropdowns to return an
    // updated version of the state it consumes.
    setFilters({ ...filters, [groupSlug]: value });
  };

  // set up group names and options for each dropdown

  const dropdownItems = [
    {
      placeholder: 'Filter by resource type',
      groupSlug: 'contentType',
      showCount: true,
      showAllCount: true,
      pluralizeOptions: true,
      options: getFilterOptionsByGroupSlug(resources, 'contentType'),
    },
    {
      placeholder: 'Filter by category',
      groupSlug: 'categories',
      showAllCount: false,
      showCount: false,
      allowMultiple: true,
      options: getFilterOptionsByGroupSlug(
        resources.filter(filterByType),
        'categories',
      ),
    },
    {
      placeholder: 'Filter by role',
      groupSlug: 'roles',
      showAllCount: false,
      showCount: false,
      allowMultiple: true,
      options: getFilterOptionsByGroupSlug(
        resources.filter(filterByType),
        'roles',
      ),
    },
  ];

  return (
    <div className="resource-browser__controls">
      <div className="resource-browser__controls-inner">
        <div className="resource-browser__controls-title">Browse by:</div>
        <div className="resource-browser__dropdowns">
          {dropdownItems.map(
            ({ groupSlug, useFilteredOptions, ...rest }, index) => {
              return (
                <div className="resource-browser__dropdown" key={index}>
                  <Dropdown
                    value={filters[groupSlug]}
                    onChange={(value) => handleSelectOption(value, groupSlug)}
                    {...rest}
                  />
                </div>
              );
            },
          )}
        </div>
        <div className="resource-browser__reset-button">
          <Button
            type="button"
            disabled={!hasActiveFilters}
            onClick={() => resetFilters()}
          >
            Reset Filters
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ResourceBrowserControls;
