import React from 'react';
import PropTypes from 'prop-types';
import { PillItem, PillsList } from '../PillsList';
import BackgroundImage from 'gatsby-background-image';
import { navigate } from 'gatsby';
import RichText from '../RichText';
import qs from 'qs';
import Link from '../Link';

const ResourceCard = (props) => {
  const {
    contentType,
    roles,
    categories,
    featuredContent,
    showIsFeatured,
    shortTitle,
    slug,
    gatedContentPreviewText,
    externalResource,
    externalResourceUrl,
    cardImage,
    cardSnippet,
    size,
    layout,
  } = props;

  const cardUrl = externalResource ? externalResourceUrl : `/resources/${slug}`;

  const handlePillClick = () => {
    const filterState = { contentType: [contentType.slug] };
    const encoded = qs.stringify(filterState, {
      indices: false,
      encode: false,
      arrayFormat: 'comma',
    });
    const url = `/resources/?${encoded}`;
    navigate(url);
  };

  const showIntroText = size === 'large' && cardSnippet;

  const isBlogPost = contentType.title === 'Blog Post';
  return (
    <div
      className={`resource-card resource-card--${layout} resource-card--${size}`}
    >
      <div className="resource-card__inner">
        <div className="resource-card__header">
          <Link href={cardUrl}>
            <BackgroundImage
              tag={'div'}
              fluid={cardImage?.fluid}
              className="resource-card__header-inner"
              style={{
                position: 'absolute',
              }}
            />
          </Link>
        </div>

        <div className="resource-card__body">
          <div className="resource-card__pills">
            <PillsList>
              {/* This can be omitted if we don't want to show featured */}
              {featuredContent && showIsFeatured ? (
                <PillItem isClickable={false}>Featured</PillItem>
              ) : null}
              {contentType ? (
                <PillItem isClickable={isBlogPost ? false : true}  onClick={handlePillClick}>
                  {contentType.title}
                </PillItem>
              ) : null}
              {/* {[...roles, ...categories].map(({ title, slug }) => (
                <PillItem>{title}</PillItem>
              ))} */}
            </PillsList>
          </div>
          <h5 className="resource-card__title">
            <Link href={cardUrl}>{shortTitle}</Link>
          </h5>
          {showIntroText && (
            <p className="resource-card__intro">{cardSnippet}</p>
          )}
          <div className="resource-card__cta">
            <Link href={cardUrl}>Learn more</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

ResourceCard.defaultProps = {
  gatedContentPreviewText: null,
  size: 'regular',
  layout: 'vertical',
  showIsFeatured: true,
  cardSnippet: null,
  externalResource: false,
  externalResourceUrl: null,
};

ResourceCard.propTypes = {
  layout: PropTypes.oneOf(['horizontal', 'vertical']),
  size: PropTypes.oneOf(['small', 'regular', 'large']),
  showIsFeatured: PropTypes.bool,
  cardSnippet: PropTypes.string,
};

export default ResourceCard;
