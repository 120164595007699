import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Image from 'gatsby-image';
import VideoPlaceholder from '../VideoPlaceholder';
import RichText from '../RichText';
import { Button } from '../Button';
import { gsap } from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { inViewTriggerThresholds } from '../../animationGlobals';

function MediaHero({ eyebrow, title, bodyText, asset, videoCoverImage, ctaLink, ctaText, video }) {
  const placeholderPosterFluid = videoCoverImage?.fluid;
  const imageFluid = asset?.fluid;
  const videoSrc = asset?.file?.url;
  const hasLink = ctaLink && ctaText;

  const contentRef = useRef();
  const sectionRef = useRef();

  useEffect(() => {
    const contentEl = contentRef.current;
    const sectionEl = sectionRef.current;

    const toCommon = {
      duration: 1,
      ease: 'power4.out',
    };

    const delayCommon = `<${toCommon.duration * 0.5}`;

    const InFromBottom = [
      {
        autoAlpha: 0,
        y: '10%',
      },
      {
        autoAlpha: 1,
        y: '0%',
        ...toCommon,
      },
      delayCommon,
    ];

    const animation = gsap.timeline({}).fromTo(contentEl, ...InFromBottom);

    const trigger = ScrollTrigger.create({
      trigger: sectionEl,
      ...inViewTriggerThresholds,
      animation: animation,
    });

    return () => {
      animation.progress(1);
      animation.kill();
      trigger.kill();
    };
  }, []);

  return (
      <section ref={sectionRef} className="media-hero">
        <div className="media-hero__inner">
          <div ref={contentRef} className="media-hero__content">
            {eyebrow && <div className="media-hero__eyebrow">{eyebrow}</div>}
            <h1 className="media-hero__title">{title}</h1>
            <div className="media-hero__body">
              <RichText source={bodyText.json}/>
            </div>
            {hasLink ? 
              <Button
                theme={'dark'}
                href={ctaLink}
                className={`nav-button hero-carousel-item__button`}
              >
                {ctaText}
              </Button>
            : null }
          </div>
          <div className="media-hero__media">
            <div className="media-hero__media-inner">
              {imageFluid ? (
                  <Image fluid={imageFluid}/>
              ) : (
                  <VideoPlaceholder
                      posterFluid={placeholderPosterFluid}
                      src={videoSrc}
                  />
              )}
            </div>
          </div>
        </div>
      </section>
  );
}

MediaHero.defaultProps = {
  title: 'Lorem ipsum dolor sit amet',
  eyebrow: '',
  bodyText:
      'Duis ligula magna, egestas ac ipsum sit amet, mattis sodales nulla. Duis iaculis consectetur iaculis. Mauris ultricies et nisi vitae tempor.',
  videoCoverImage: {
    fluid: {
      src: '',
    },
  },
  ctaLink: null,
  ctaText: null,
  asset: {
    file: {
      url: '',
    },
  },
};

MediaHero.propTypes = {
  ctaText: PropTypes.string.isRequired,
  ctaLink: PropTypes.string,
};

export default MediaHero;
